
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { Component, Vue } from "vue-property-decorator";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

@Component({ components: { PerfectScrollbar } })
export default class SignUp extends Vue {
  /* eslint-disable */
  public address: any = {};
  public fullName: any = {};
  public userInfo: any = {
    fullName: this.fullName,
    address: this.address
  };
  
  public isSubmitting = false;
  public passwordFieldType = 'password'
  public stateAndCounty = [];

  public async signUp() {
    try {
      this.isSubmitting = true;
      this.callLoader(true, 2);
      const response = await Axios.post(
        BASE_API_URL + "authentication/signUp",
        this.userInfo
      );
      if (response.data.status == 200) {
        this.callLoader(false, null);
        this.$snotify.success(
          "You have been registered successfully. Verification mail has been sent to you email."
        );
        this.$router.push("/");
      } else if (response.data.status == 400) {
        this.callLoader(false, null);
        this.$snotify.error("Email Address already Exist.");
      } else {
        this.callLoader(false, null);
        this.$snotify.error("Something went wrong.");
      }
    } catch (error) {
      this.callLoader(false, null);
      console.log(error);
    }
    this.isSubmitting = false;
  }

  public trimEmail(){
    this.userInfo.email = this.userInfo.email.trim()
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }
  
  public switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    }

  public async getStatesAndCounty() {
      try {
        const response = await Axios.get(
          BASE_API_URL + "common/getStatesAndCounty"
          );
        this.stateAndCounty = response.data; 
         
      } catch (error) {
        console.log(error);
      }
    }

  async mounted() {
    document.title = this.$route.meta.title;
    await this.getStatesAndCounty();
  }
}
